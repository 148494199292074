"use client";
import type { Metadata } from "next";
import { Inter } from "next/font/google";
// import { enableStaticRendering } from "mobx-react-lite";
import "./globals.css";

// enableStaticRendering(true);

const inter = Inter({ subsets: ["latin"] });

// export const metadata: Metadata = {
//   title: "Patient Dashboard - Smart Assist - PEDIG - Vivid Vision",
//   description: "Patient Dashboard for Smart Assist - PEDIG - Vivid Vision",
// };

export default function RootLayout({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) {
  return (
    <html lang="en">
      <body className={`${inter.className}flex min-h-screen flex-col`}>{children}</body>
    </html>
  );
}
